/**
 * Copyright 2020 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { LandingPageComp } from '@/features/landingPage/pages/LandingPage';
import { prefetchLandingPageData } from '@/features/landingPage/api/getLandingPageData';
import { prefetchContentStackLandingPageData } from '@/features/landingPage/api/getContentStackLandingPageData';
import { PageContextType } from '@/next-types';
import { configureSharedPageData } from '@/utils/configureSharedPageData.server';
import { dehydrate } from '@tanstack/react-query';
import { getHeaderDataFromCache } from '@/features/header/api/getHeader';
import { getPreferredVehicle } from '@/features/header/utils/getPreferredVehicle';
import { prefetchPreFooterData } from '@/features/prefooter';
import { getPathnameFromAsPath } from '@/utils/urlHelpers';
import { prefetchRewardDetails, useRewardDetails } from '@/features/rewards/api/getRewardDetails';
import { getKiboDecisionFlag } from '@/features/kibo';

export const config = {
  unstable_JsPreload: false,
};

function RewardsPage() {
  const { data } = useRewardDetails();
  if (data?.creditsPerReward === '3') {
    return <LandingPageComp pageName="faster" />;
  }
  return <LandingPageComp pageName="rewards" />;
}

RewardsPage.getInitialProps = async (ctx: PageContextType) => {
  const { asPath } = ctx;
  if (__IS_SERVER__) {
    const { axios, queryClient, initialAppState } = await configureSharedPageData(ctx);
    const headerData = getHeaderDataFromCache(queryClient);
    const catalogVehicleId = getPreferredVehicle(headerData)?.catalogVehicleId ?? '0';

    const userAnonymous = headerData?.myAccountMap?.anonymous !== 'false';

    const prefetchFooterCall = prefetchPreFooterData(
      queryClient,
      {
        locale: initialAppState.locale,
        seourl: getPathnameFromAsPath(asPath).replace(/(\/bn\/|\/b\/).*/, ''),
        vehicleId: catalogVehicleId,
        isCMSEnabled: false,
      },
      axios
    );

    const contentStackLandingPageEnabled = getKiboDecisionFlag(
      queryClient,
      'cmsLandingPagePhaseOneEnabled'
    );
    const prefetchLandingPageCall = contentStackLandingPageEnabled
      ? prefetchContentStackLandingPageData(
          queryClient,
          {
            ...ctx,
            query: {
              ...ctx.query,
              pageName: 'rewards',
            },
          },
          headerData?.segments ?? []
        )
      : prefetchLandingPageData(queryClient, 'rewards', axios);

    let rewardsActivityPromise: Promise<void> | undefined;

    if (!userAnonymous) {
      rewardsActivityPromise = prefetchRewardDetails(axios, queryClient, !userAnonymous);
    }

    await Promise.all([prefetchFooterCall, prefetchLandingPageCall, rewardsActivityPromise]);

    return {
      dehydratedState: dehydrate(queryClient),
      initialAppState,
    };
  }

  return {};
};

export default RewardsPage;
